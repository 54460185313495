import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { AccessCodeDialog } from '../../../Common/Components/AccessCodeDialog/AccessCodeDialog'
import { AuthPageFooter } from '../../../Components/Authentication/AuthPageFooter/AuthPageFooter'
import OAuth2Login from '../../../Components/Authentication/OAuth2Login/OAuth2Login'
import { updateCurrentUser } from '../../../Reducers/authSlice'
import { useAppDispatch } from '../../../Reducers/hooks'
import {
  authService,
  eventService,
  FrontendTypes,
  localStorageService,
  PreferenceType,
} from '../../../Services/services-index'
import { setMiniMap } from '../../SettingsContainer/SettingsSlice'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#1E1E1E',
    '& div[class*="MuiGrid-spacing"]': {
      margin: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        height: '100vh',
      },
    },
    '& div[class*="MuiGrid-item"]': {
      padding: '0 24px',
    },
  },
  item: {
    width: '100%',
  },
}))

function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

const OAuth2Container: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const location: any = useLocation()
  const query = useQuery()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [providerValue, setProvider] = useState<any>()
  const [callbackCode, setCallbackcode] = useState<any>()
  const [eventCode, setEventCode] = useState<string>('')
  const [accessCodeFromQuery, setAccessCodeFromQuery] = useState<string>('')
  const [eventCodeFromQuery, setEventCodeFromQuery] = useState<string>('')
  const [hasEventBaseDeskEmailId, setHasEventBaseDeskEmailId] = useState<boolean>(false)
  const [eventBaseHelpDeskEmailId, setEventBaseHelpDeskEmailId] = useState<string>('')
  const [retryToken, setRetryToken] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [accessCodeErrorMessage, setAccessCodeErrorMessage] = useState<string>('')
  const [eventFieldError, setEventFieldError] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [invalidAccessCodeEntered, setInvalidAccessCodeEntered] = useState<boolean>(false)

  const { from } = location.state || { from: { pathname: '/' } }

  useEffect(() => {
    if (localStorageService.getLocalStorageItemValue('eventCode')) {
      const eventCode = JSON.parse(localStorageService.getLocalStorageItemValue('eventCode') || '')
      setEventCode(eventCode)
      setEventCodeFromQuery(eventCode)
    }
    if (localStorageService.getLocalStorageItemValue('accessCode')) {
      setAccessCodeFromQuery(
        JSON.parse(localStorageService.getLocalStorageItemValue('accessCode') || '')
      )
    }
  }, [])

  useEffect(() => {
    const activeProvider = window.location.pathname.substring(
      window.location.pathname.lastIndexOf('/') + 1
    )
    const callbackCode = query.get('code')
    setCallbackcode(callbackCode)
    setProvider(activeProvider)
  }, [eventCode, eventFieldError, providerValue, callbackCode])

  const onSubmit = async () => {
    if (accessCodeFromQuery) {
      oAuth2SignIn(accessCodeFromQuery)
    } else {
      oAuth2SignIn()
    }
  }

  // On EventCode field value change
  const onEventValueChange = (value?: any | undefined) => {
    setEventCode(value)
    if (value) {
      setEventFieldError(false)
    }
    if (!value) {
      setEventFieldError(true)
    }
  }

  const checkMiniMapStateAtStart = (eventCode: string) => {
    if (eventCode) {
      return eventService
        .getPreference({
          type: PreferenceType.SPECIFIC,
          name: 'mini_map_at_start',
          event_code: eventCode,
        })
        .then((res: any) => {
          const response = res?.data?.[0]
          if (response) {
            const miniMapState = response?.value === 'yes' ? true : false
            miniMapState ? dispatch(setMiniMap(true)) : dispatch(setMiniMap(false))
            localStorageService.setLocalStorageItemValue(
              'enableMiniMap',
              JSON.stringify(miniMapState)
            )
          }
        })
        .catch((err: any) => {
          try {
            const errorMessage = err.message || 'An unknown error occurred'
            console.error(`Error: ${errorMessage}`)
          } catch (parseError) {
            console.error('An unexpected error occurred.')
          }
        })
    }
    return
  }

  const handleClose = () => {
    setOpenDialog(false)
    setErrorMessage(accessCodeErrorMessage)
  }

  const handleConfirm = (accessCode: string) => {
    oAuth2SignIn(accessCode)
  }

  const oAuth2SignIn = (accessCode?: string) => {
    if (!accessCode) {
      accessCode = ''
    }
    let projectType: any
    const traverseRedirect = localStorageService.getLocalStorageItemValue('traverseRedirect')
    if (traverseRedirect) {
      projectType = FrontendTypes.TRAVERSE
    } else {
      projectType = FrontendTypes.VISITOR
    }
    authService
      .postOauth2(projectType, providerValue, eventCode, accessCode, retryToken, callbackCode)
      .then((res: any) => {
        if (res.data && res.data.user_data && res.data.user_data.id) {
          dispatch(updateCurrentUser(res.data.user_data))
          checkMiniMapStateAtStart(res.data.user_data?.event_code)

          // Store values in localstore
          localStorageService.setLocalStorageItemValue(
            'currentUser',
            JSON.stringify(res.data.user_data)
          )
          localStorageService.setLocalStorageItemValue('enableRoomChatFeed', JSON.stringify(true))
          localStorageService.setLocalStorageItemValue('transitionContent', JSON.stringify(true))
          localStorageService.setLocalStorageItemValue('activeUserStatus', 'available')
          localStorageService.setLocalStorageItemValue('disabledPopups', JSON.stringify([]))
          localStorageService.clearLocalStorageItemValue('eventCode')

          const traverseRedirect = localStorageService.getLocalStorageItemValue('traverseRedirect')
          if (traverseRedirect) {
            localStorageService.clearLocalStorageItemValue('traverseRedirect')
            history.replace(from)
            history.push('/auth')
          } else {
            history.replace(from)
            history.push('/lobby')
          }
        }
      })
      // We have to send a POST request and receive an error response to obtain
      // user information and decide whether to prompt for an access code
      // A new retry token is received after every request
      .catch((err: any) => {
        const errorCode = err?.response?.data?.error_code
        const errorText = err?.response?.data?.message
        const _retryToken = err?.response?.data?.retry_token
        setRetryToken(_retryToken ? _retryToken : '')

        // Missing / invalid access code response
        if (_retryToken && errorText.toLowerCase().includes('access code')) {
          setAccessCodeErrorMessage(errorText)
          setOpenDialog(true)
          if (accessCode) {
            setInvalidAccessCodeEntered(true)
          }
        }
        // Other responses, e.g. invalid event code
        else {
          setErrorMessage(errorText)
          setEventCode('')
          setInvalidAccessCodeEntered(false)
          setOpenDialog(false)
        }

        // User takes too long to enter access code
        if (errorText === 'Login session expired.') {
          setTimeout(() => {
            history.push('/auth/login')
          }, 4000)
        }

        if (errorCode === 401.2) {
          setTimeout(() => {
            history.push('/auth/login')
          }, 4000)
        }
      })
  }

  useEffect(() => {
    if (eventCodeFromQuery && !eventBaseHelpDeskEmailId) {
      eventService
        .getPreference({
          type: PreferenceType.SPECIFIC,
          name: 'help_desk_email',
          event_code: eventCodeFromQuery.toString(),
        })
        .then((res: any) => {
          const response = res?.data?.[0]
          if (response && response?.value?.length) {
            setHasEventBaseDeskEmailId(true)
            setEventBaseHelpDeskEmailId(response?.value)
          }
        })
        .catch((err: any) => {
          try {
            const errorMessage = err.message || 'An unknown error occurred'
            console.error(`Error: ${errorMessage}`)
          } catch (parseError) {
            console.error('An unexpected error occurred.')
          }
        })
    }
  }, [eventCodeFromQuery])

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
        <Grid item className={classes.item}>
          <OAuth2Login
            eventCode={eventCode}
            eventCodeFromQuery={eventCodeFromQuery}
            errorMessage={errorMessage}
            eventFieldError={eventFieldError}
            onEventValueChange={onEventValueChange}
            onClick={onSubmit}
          ></OAuth2Login>
          <AccessCodeDialog
            titleText={t('authPages.accessCodeDialog.header')}
            contentText={t('authPages.accessCodeDialog.content')}
            placeholder={t('authPages.accessCodeDialog.placeholder')}
            cancelText={t('authPages.accessCodeDialog.cancel')}
            confirmText={t('authPages.accessCodeDialog.confirm')}
            open={openDialog}
            invalidCodeEntered={invalidAccessCodeEntered}
            errorMessage={t('authPages.accessCodeDialog.errorMessage')}
            onConfirm={handleConfirm}
            onCancel={handleClose}
          />
        </Grid>
        <Grid item className={classes.item}>
          <AuthPageFooter
            hasEventBaseDeskEmailId={hasEventBaseDeskEmailId}
            eventBaseHelpDeskEmailId={eventBaseHelpDeskEmailId}
          />
        </Grid>
      </Grid>
    </div>
  )
}
export default OAuth2Container

import React, { FC, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import RegisterForm from '../../../Components/Authentication/RegisterForm/RegisterForm'
import { AuthPageFooter } from '../../../Components/Authentication/AuthPageFooter/AuthPageFooter'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { laterRegisterUser } from '../../../Reducers/authSlice'
import { useAppDispatch } from '../../../Reducers/hooks'
import {
  User,
  authService,
  eventService,
  Preference,
  PreferenceType,
} from '../../../Services/services-index'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& div[class*="MuiGrid-spacing"]': {
        margin: 0,
        width: '100%',
        paddingBottom: '32px',
      },
      '& div[class*="MuiGrid-item"]': {
        padding: '0 24px',
      },
    },
    item: {
      width: '100%',
    },
    headerWrapper: {
      marginTop: '43px',
      maxWidth: '400px',
    },
  })
)

function useQuery() {
  return new URLSearchParams(decodeURI(useLocation().search))
}

function getEventCodeQuery(query: URLSearchParams) {
  const eventCodeQuery = query.get('event')
  let eventCode: string
  if (!eventCodeQuery) {
    eventCode = ''
  } else {
    eventCode = eventCodeQuery
  }
  return eventCode
}

const RegisterContainer: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const query = useQuery()
  const eventCode = getEventCodeQuery(query)
  const [errorResponse, setErrorResponse] = useState('')
  const [preferences, setPreferences] = useState<Preference[]>()
  const SIGNUP_SUCCESS_MESSAGE = 'Sign up successful!'

  const getPrefLabelValue = (fieldname: string) => {
    let fieldValue
    if (preferences) {
      const pref = [...preferences].filter((pref) => pref.name === fieldname)
      if (pref.length > 0) {
        fieldValue = pref[0].value
      }
    }
    return fieldValue
  }
  const mainTitleLabel = getPrefLabelValue('MainTitle_Label')

  // On render of mutate state data
  const { mutate, data, error, isError, isSuccess } = useMutation((user: User) =>
    authService.signup(user, true)
  )

  useEffect(() => {
    if (eventCode) {
      const body = {
        event_code: eventCode,
        type: PreferenceType.SPECIFIC,
        category: 'register',
      }
      if (!preferences && body) {
        eventService
          .getPreference(body)
          .then((response: any) => {
            if (response && response?.data) {
              setPreferences(response.data)
            }
          })
          .catch((err: any) => {
            try {
              const errorMessage = err.message || 'An unknown error occurred'
              console.error(`Error: ${errorMessage}`)
            } catch (parseError) {
              console.error('An unexpected error occurred.')
            }
          })
      }
    }
  }, [preferences, eventCode])

  useEffect(() => {
    const resultMessage = (data as any)?.data?.message
    if (isError || (resultMessage && resultMessage !== SIGNUP_SUCCESS_MESSAGE)) {
      if (resultMessage) {
        setErrorResponse(resultMessage)
      } else {
        setErrorResponse((error as any)?.response?.data?.message)
      }
    }
  }, [isError, errorResponse, data])

  useEffect(() => {
    const resultMessage = (data as any)?.data?.message
    if (isSuccess && resultMessage && resultMessage === SIGNUP_SUCCESS_MESSAGE) {
      const { user_data } = (data as any).data
      handleRegisterSuccess(user_data)
    }
  }, [isSuccess, error, data])

  const handleRegisterSuccess = (userData: any) => {
    dispatch(laterRegisterUser(userData))
    history.push('/auth/registration-success')
  }

  const onSubmit = (
    firstname: string,
    lastname: string,
    email: string,
    password: string,
    eventCode: string
  ) => {
    mutate({
      firstname: firstname,
      lastname: lastname,
      email: email,
      password: password,
      active: true,
      event_code: eventCode,
    })
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={6}>
        <Grid item className={classes.item}>
          <RegisterForm
            onSubmit={onSubmit}
            eventCodeProp={eventCode}
            preferences={preferences}
            errorResponse={errorResponse}
            label={mainTitleLabel}
          />
          <AuthPageFooter showLogInPageInfo={false}></AuthPageFooter>
        </Grid>
      </Grid>
    </div>
  )
}

export default RegisterContainer
